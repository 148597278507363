
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { mdiArrowRight, mdiArrowBottomRight } from '@mdi/js';

export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();
    const user = ref('');
    const password = ref('');

    if (store.getters.validToken) {
      router.push({ name: 'AdminHome' });
    }

    const onLogin = () => {
      store.dispatch('login', { username: user.value, password: password.value }).then(() => {
        router.push({ name: 'AdminHome' });
      });
    };

    return {
      user,
      password,
      onLogin,
      mdiArrowRight,
      mdiArrowBottomRight,
    };
  },
});
