<style lang="scss" scoped>
#login-wrapper {
  width: 100vw;
  height: 100vh;
  //border:1px solid yellow;
  background: rgb(238, 238, 238);
  background: linear-gradient(312deg, rgba(238, 238, 238, 1) 0%, rgba(255, 255, 255, 1) 100%);
  position: relative;

  @media (max-width: $width-lg) {
    height: auto !important;
  }
  @media (max-width: $width-sm) {
    background: transparent;
  }
  .login-inner {
    width: 1024px;

    transform: translate(-50%, -50%);
    position: relative;
    top: 50%;
    left: 50%;
    @media (max-width: 1150px) {
      width: 800px;

      transform: translate(-50%, -50%);
      position: relative;
      top: 50%;
      left: 50%;
    }
    @media (max-width: $width-lg) {
      width: 100%;
      transform: translate(0%, 0%);
      position: relative;
      top: 0%;
      left: 0%;
      //border:1px solid blue;
    }
    .small-padding {
      @media (max-width: $width-lg) {
      }
    }
    [class*='col-'] {
      //border:1px solid green;
    }
    .img-box-outer {
      @media (max-width: $width-lg) {
        margin-top: 60px;
        margin-bottom: 60px;
        //border:1px solid red;
      }
      @media (max-width: $width-sm) {
        margin-bottom: 20px;
        margin-top: 30px;
        width: 90%;
      }
    }
    .img-box {
      width: 100%;
      height: 500px;
      //background:green;
      animation-name: slidein;
      animation-duration: 2s;
      animation-iteration-count: 1;
      position: relative;
      z-index: 5;
      background-size: cover;
      background-position: center center;
      @media (max-width: $width-sm) {
        height: 300px;
      }
    }
    .frame-outer {
      width: 100%;
      height: 500px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;
      @media (max-width: $width-sm) {
        display: none;
        height: 300px;
      }
      .frame {
        position: absolute;
        background: $coral;
      }
      $framebh: 8px;
      $framepos: 40px;
      .frame1 {
        width: 100%;
        height: $framebh;
        left: -$framepos;
        top: -$framepos;
        animation-name: ani-frame1;
        animation-duration: 2s;
      }
      .frame2 {
        width: $framebh;
        height: 100%;
        left: -$framepos;
        top: -$framepos;
        animation-name: ani-frame2;
        animation-duration: 2s;
      }

      .frame3 {
        width: 100%;
        height: $framebh;
        right: $framepos;
        bottom: $framepos;
        animation-name: ani-frame1;
        animation-duration: 2s;
      }
      .frame4 {
        width: $framebh;
        height: 100%;
        right: $framepos;
        bottom: $framepos;
        animation-name: ani-frame2;
        animation-duration: 2s;
      }
      @keyframes ani-frame1 {
        from {
          width: 0%;
        }
        to {
          width: 100%;
        }
      }
      @keyframes ani-frame2 {
        from {
          height: 0%;
        }
        to {
          height: 100%;
        }
      }
    }
    .login-box {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      //border:1px solid red;
      padding: 0px 40px;
      z-index: 1;
      animation-name: slidein2;
      animation-duration: 3s;
      animation-iteration-count: 1;
      @media (max-width: $width-lg) {
        top: 0%;
        transform: translateY(0%);
        padding: 40px 20px;
        max-width: 80%;
        left: 10%;
      }
      @media (max-width: 400px) {
        max-width: 90%;
        left: 5%;
      }
      .img-logo {
        max-width: 140px;
        margin-bottom: 30px;
      }
      .form-group {
        //border:1px solid red;
        margin-bottom: 15px;
        .label-input {
          margin-bottom: 5px;
          display: block;
          font-size: 14px;
          letter-spacing: 1px;
        }
        input,
        textarea,
        select {
          display: block;
          width: 100%;
          border-radius: 0 !important;
          border: 1px solid #eee;
          padding: 0.7em 0.8em;
          outline: none;
          &:focus {
            border-radius: 0 !important;
            border: 1px solid #ccc;
            box-shadow: none;
          }
        }

        textarea {
          min-height: 180px;
        }
      }

      .btn-login {
        border: 0;
        background: none;
        padding: 10px 10px;
        color: #000;
        transition: all 300ms ease;
        color: #888;
        letter-spacing: 2px;

        svg {
          width: 20px;
          height: 20px;
          transform: rotate(-45deg);
          margin-left: 5px;
          transition: all 300ms ease;
          fill: #888;
        }
        &:hover {
          padding-left: 20px;
          color: #333;
          svg {
            transform: rotate(-405deg);
            fill: #333;
          }
        }
      }
    }
  }
}

@keyframes slidein {
  from {
    opacity: 0.5;
    transform: translateX(-200%);
    visibility: hidden;
  }
  to {
    opacity: 1;
    transform: translatex(0);
    visibility: visible;
  }
}

@keyframes slidein2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>

<template>
  <div class="login-bg">
    <div id="login-wrapper">
      <div class="login-inner">
        <div class="row justify-content-center">
          <div class="col-lg-6 col-md-8 img-box-outer position-relative p-0">
            <div class="img-box shadow" :style="{ 'background-image': `url(${require('@/assets/img/header-img-styria.jpg')})` }"></div>
            <div class="frame-outer">
              <div class="frame frame1"></div>
              <div class="frame frame2"></div>
              <div class="frame frame3"></div>
              <div class="frame frame4"></div>
            </div>
          </div>
          <div class="col-lg-6 ">
            <div class="login-box">
              <img src="@/assets/img/styria-media-group-logo.svg" alt="Kontakt" class="img-fluid img-logo" />

              <div class="form-group"><span class="label-input">Benutzername </span> <input type="text" v-model="user" /></div>
              <div class="form-group"><span class="label-input">Passwort </span> <input type="password" v-model="password" /></div>
              <button class="btn-login" @click="onLogin">Login <Icon :path="mdiArrowBottomRight" /></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { mdiArrowRight, mdiArrowBottomRight } from '@mdi/js';

export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();
    const user = ref('');
    const password = ref('');

    if (store.getters.validToken) {
      router.push({ name: 'AdminHome' });
    }

    const onLogin = () => {
      store.dispatch('login', { username: user.value, password: password.value }).then(() => {
        router.push({ name: 'AdminHome' });
      });
    };

    return {
      user,
      password,
      onLogin,
      mdiArrowRight,
      mdiArrowBottomRight,
    };
  },
});
</script>
